import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    component: () => import("../layout/B2bLayout.vue"),
    children: [
      {
        path: "",
        name: "accueil",
        components: {
          cookies: () => import("../layout/B2bLayout.cookies"),
          default: () => import("../views/B2b2.vue"),
          footer: () => import("../layout/B2bLayout.footer.vue"),
        },
      },
    ],
  },
  {
    path: "/confidentialite",
    component: () => import("../layout/B2bLayout.vue"),
    children: [
      {
        path: "",
        name: "confidentialite",
        components: {
          cookies: () => import("../layout/B2bLayout.cookies"),
          default: () => import("../views/statics/confidentialite.vue"),
          header: () => import("../layout/B2bLayout.header.vue"),
          footer: () => import("../layout/B2bLayout.footer.vue"),
        },
      },
    ],
  },
  
  {
    path: "/cgu",
    component: () => import("../layout/B2bLayout.vue"),
    children: [
      {
        path: "",
        name: "cgu",
        components: {
          cookies: () => import("../layout/B2bLayout.cookies"),
          default: () => import("../views/statics/cgu.vue"),
          header: () => import("../layout/B2bLayout.header.vue"),
          footer: () => import("../layout/B2bLayout.footer.vue"),
        },
      },
    ],
  },

  
  {
    path: "/mentions-legales",
    component: () => import("../layout/B2bLayout.vue"),
    children: [
      {
        path: "",
        name: "mention-legales",
        components: {
          cookies: () => import("../layout/B2bLayout.cookies"),
          default: () => import("../views/statics/mention.vue"),
          header: () => import("../layout/B2bLayout.header.vue"),
          footer: () => import("../layout/B2bLayout.footer.vue"),
        },
      },
    ],
  },
  {
    path: "/inscription",
    name: "inscription",
    component: () => import("../views/B2b3.vue"),
  },
  {
    path: "/connexion",
    name: "connexion",
    component: () => import("../views/B2b4.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/B2b5.vue"),
  },
  {
    path: "/aide",
    component: () => import("../layout/B2bLayout.vue"),
    children: [
      {
        path: "",
        name: "aide",
        components: {
          cookies: () => import("../layout/B2bLayout.cookies"),
          default: () => import("../views/B2b6.vue"),
          header: () => import("../layout/B2bLayout.header.vue"),
          footer: () => import("../layout/B2bLayout.footer.vue"),
        },
        children: [
          {
            path: "",
            components: {
              outilExval: () => import("../components/b2b2/Question.vue"),
            },
          },
        ],
      },
    ],
  },
  {
    path: "/nos-outils",
    component: () => import("../layout/B2bLayout.vue"),
    children: [
      {
        path: "",
        name: "nos-outils",
        components: {
          cookies: () => import("../layout/B2bLayout.cookies"),
          default: () => import("../views/B2b6.nos_outils.vue"),
          header: () => import("../layout/B2bLayout.header.vue"),
          footer: () => import("../layout/B2bLayout.footer.vue"),
        },
      },
    ],
  },
  {
    path: "/tarifs",
    name: "tarifs",
    component: () => import("../layout/B2bLayout.vue"),
    children: [
      {
        path: "",
        components: {
          cookies: () => import("../layout/B2bLayout.cookies"),
          default: () => import("../views/B2b8.vue"),
          header: () => import("../layout/B2bLayout.header.vue"),
          footer: () => import("../layout/B2bLayout.footer.vue"),
        },
      },
      {
        path: "souscription",
        name: "souscription",
        components: {
          cookies: () => import("../layout/B2bLayout.cookies"),
          default: () => import("../views/B2b9.vue"),
          header: () => import("../layout/B2bLayout.header.vue"),
          footer: () => import("../layout/B2bLayout.footer.vue"),
        },
      },
      {
        path: "souscription/validation",
        name: "offre-souscrition-validation",
        components: {
          cookies: () => import("../layout/B2bLayout.cookies"),
          default: () => import("../views/B2b10.vue"),
          header: () => import("../layout/B2bLayout.header.vue"),
          footer: () => import("../layout/B2bLayout.footer.vue"),
        },
      },
      {
        path: "souscription/validation/terminaison",
        name: "offre-souscrition-validation-terminaison",
        components: {
          cookies: () => import("../layout/B2bLayout.cookies"),
          default: () => import("../views/B2B11.vue"),
          header: () => import("../layout/B2bLayout.header.vue"),
          footer: () => import("../layout/B2bLayout.footer.vue"),
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach(function(to, from, next) {
  if (to.name !== "accueil") {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }
  next();
});
export default router;
