import { createApp } from "vue";
import App from "./App.vue";
import VueSmoothScroll from "vue3-smooth-scroll";
import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import VueCookies from 'vue3-cookies';
import VueGtag from "vue-gtag";
import "./assets/styles/tailwind.css";
import "./assets/styles/other.css";
import router from "./router";

const app = createApp(App);

app.use(VueSmoothScroll);
app.use(VueTelInput);
app.use(VueCookies, {
    expireTimes: "360d",
    path: "/",
    domain: "",
    secure: true,
    sameSite: "None"
  });
app.use(VueGtag, {
    config: { id: 'G-FQGL5REE3P' },
    enabled: false,
});
app.use(router);
app.mount("#app");
