<template>
  <router-view />
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
});
</script>
<style>
* {
  font-family: Poppins;
}

.vti__dropdown-list {
  z-index: 90 !important;
  top: 51px !important;
}
.vti__dropdown-list strong {
  font-size: 13px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #363660;
  margin-top: 40px;
  margin-bottom: 15px;
}
.vti__input {
  height: 50px;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #363660;
  padding: 12px 16px 10px 16px;
  background: none;
  z-index: 10;
  outline: none;
  background: #fff;
}
.selected_text {
  font-size: 11px !important;
}
.slct .container {
  top: 2px;
}
.lds-roller {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  left: 50%;
  top: 50%;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  background: #0000008c;
  z-index: 99;
  top: 0;
  left: 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: block;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.19px;
  color: #373563;
  margin: 7px 24px 7px 0;
}
h1 {
  line-height: 1 !important;
}
@media (max-width: 900px) {
  .tarif-pro {
    margin: 20px auto !important;
    display: block !important;
  }
}
@media (max-width: 750px) {
  #aize {
    display: block !important;
  }
  #aize .tarif-pro {
    margin: 20px auto !important;
    display: block !important;
  }
}
@media (max-width: 450px) {
  #aize .tarif-pro,
  .tarif-pro {
    width: 93% !important;
    min-height: 680px !important;
    height: auto !important;
    padding-left: 24px !important;
    margin-top: 20px !important;
  }
}
@media (max-width: 1024px) {
  .dropdown-menu {
    left: -63px !important;
  }
}
.b2blayout_content {
  overflow: hidden;
}

.search,
.sous_menu,
.sous_menu_content {
  margin: 0 auto;
}
@media (max-width: 750px) {
  .search,
  .sous_menu,
  .sous_menu_content {
    max-width: 95%;
  }
}
#right {
  max-width: 574px;
  min-width: 574px;
}
@media (max-width: 1026px) {
  #quest,
  #obj {
    width: 100% !important;
    display: block !important;
  }
  .no-grid .Testimonial {
    width: 310px !important;
  }
  .no-grid .Testimonial .content {
    width: auto !important;
  }
}
@media (max-width: 768px) {
  .no-grid,
  #aize {
    display: block !important;
  }
  .no-grid .Testimonial {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .no-grid .card {
    width: 95% !important;
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .no-grid .card .content {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}
.page h1 {
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.53;
  letter-spacing: -1px;
  text-align: left;
  color: #363660;
}
.page h2 {
  margin: 30px 0px 0px 0px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.5px;
  text-align: left;
  color: #363660;
  width: 100%;
}
.page h3 {
  width: 100%;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: 3px;
  text-align: left;
  color: #e94a73;
}
.page h4 {
  width: 100%;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: 3px;
  text-align: left;
  color: #e94a73;
}
.page p {
  content: none;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #363660;
  transition: all 500ms;
}

#md {
  text-align: center;
  width: 50px;
}
</style>
